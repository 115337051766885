.homePage {
    min-height: 100vh;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.storeLinks {
    display: flex;
    align-items: center;
    gap: 30px;
}

.storeLinks a img {
    max-width: 200px;
    width: 100%;
}
.page-container {
    max-width: 1340px;
    padding: 0 20px;
    margin: 0 auto;
}
.homePage .home {
    display: flex;
    align-items: center;
    gap: 50px;
}
.homePage .home .left,
.homePage .home .right {
    width: calc(50% - 25px);
}
.homePage .home {
    display: flex;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
    transform: translateY(-60px);
}
.page-container {
    max-width: 1300px;
    margin: 0 auto;
}
.homePage .home .left > img {
    margin-bottom: 15px;
    transform: translateX(-10px);
}
.homePage .home h1 {
    font-family: 'Nexa';
    font-size: 40px;
    line-height: 1.2;
    max-width: 90%;
    padding-bottom: 15px;
    color: #141414;
}
.homePage .home p {
    font-size: 16px;
    line-height: 1.4;
    padding-bottom: 30px;
    max-width: 95%;
}
.contact-details {
    padding: 15px 30px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(195deg, #42424a, #191919);
    border-radius: 15px;
    margin: 0 15px 15px;
}
.contact-details .page-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-details ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.contact-details ul a {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    color: #FFFFFF;
}
.contact-details ul a img {
    max-width: 20px;
    max-height: 20px;
}
img.mobile-only {
    display: none;
}
img.ahura {
    width:14px;
    padding-left:2px;
    transform: translateY(2px);
}
.homePage .home .left > img.logo {
    margin-bottom: 15px;
    width: 150px;
}
.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px 0;
    box-shadow: 0 3px 20px 0 rgba(0 0 0 / 10%);
    z-index: 9999;
    background-color: #ffffff;
}
.header .page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 30px;
}
.header ul a {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    color: #141414;
}
.header ul a img {
    max-width: 20px;
}
/* .contact-details .page-container {
    justify-content: center;
} */
.homePage .home {
    transform: translateY(0);
}
.contact-details ul:first-child {
    display: none;
}
.homePage .home .left > img.logo {
    display: none;
}
.contact-details .MuiBox-root {
    color: #d6d6d6;
}
.contact-details .MuiBox-root a span {
    color: #FFFFFF !important;
}

@media screen and (max-width: 1024px) {
    .homePage .home h1 {
        font-size: 33px;
    }
    .homePage .home p {
        font-size: 15px;
    }
    .storeLinks a img {
        max-width: 170px;
    }
}
@media screen and (max-width: 991px) {
    img.mobile-only {
        display: block;
    }
    .homePage {
        padding: 80px 0 120px;
    }
    .homePage .home .right img {
        max-width: 200px;
        width: 100%;
    }
    .homePage .home .left {
        width: 85%;
    }
    .homePage .home {
        text-align: center;
        justify-content: center;
    }
    .storeLinks {
        justify-content: center;
    }
    .homePage .home .left > img {
        transform: none;
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }
    .homePage .home .left > img.logo {
        margin-bottom: 15px;
        width: 150px;
    }
    .homePage .home h1, .homePage .home p {
        max-width: 100%;
    }
    .homePage {
        padding-top: 160px;
    }
}
@media screen and (max-width: 767px) {
    .homePage .home .left {
        width: 100%;
    }
    .contact-details .page-container {
        flex-direction: column;
        gap: 20px;
    }
    .contact-details ul a {
        font-size: 13px;
    }
    .homePage .home h1 {
        font-size: 28px;
        line-height: 1.4;
    }
    .homePage {
        padding: 50px 0 80px;
    }
    .homePage .home .left > img.logo {
        width: 120px;
    }
    .contact-details ul a {
        font-size: 12px;
    }
    .contact-details ul:last-child a img {
        max-width: 12px;
    }
    .homePage {
        padding-top: 50px;
    }
    .header ul {
        gap: 10px;
        flex-direction: column;
        align-items: flex-end;
    }
    .homePage .home .left > img {
        margin: 0 auto 0px;
    }
    .header ul {
        display: none;
    }
    .contact-details ul:first-child {
        display: flex;
    } 
    .header {
        display: none;
    }
    .homePage .home .left > img.logo {
        display: block;
        margin-bottom: 20px;
        width: 100px;
    }
    .contact-details {
        padding: 15px 10px;
    }
    .contact-details .page-container > .MuiBox-root {
        gap: 10px;
    }
    .contact-details .MuiBox-root, .contact-details .MuiBox-root a span {
        font-size: 12px;
    }
}