@font-face {
    font-family: 'Nexa';
    src: url('./fonts/Nexa-Heavy.eot');
    src: url('./fonts/Nexa-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Nexa-Heavy.woff2') format('woff2'),
        url('./fonts/Nexa-Heavy.woff') format('woff'),
        url('./fonts/Nexa-Heavy.ttf') format('truetype'),
        url('./fonts/Nexa-Heavy.svg#Nexa-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa Extra';
    src: url('./fonts/Nexa-ExtraLight.eot');
    src: url('./fonts/Nexa-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Nexa-ExtraLight.woff2') format('woff2'),
        url('./fonts/Nexa-ExtraLight.woff') format('woff'),
        url('./fonts/Nexa-ExtraLight.ttf') format('truetype'),
        url('./fonts/Nexa-ExtraLight.svg#Nexa-ExtraLight') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mukta';
    src: url('./fonts/Mukta-Medium.eot');
    src: url('./fonts/Mukta-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mukta-Medium.woff2') format('woff2'),
        url('./fonts/Mukta-Medium.woff') format('woff'),
        url('./fonts/Mukta-Medium.ttf') format('truetype'),
        url('./fonts/Mukta-Medium.svg#Mukta-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mukta';
    src: url('./fonts/Mukta-Light.eot');
    src: url('./fonts/Mukta-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mukta-Light.woff2') format('woff2'),
        url('./fonts/Mukta-Light.woff') format('woff'),
        url('./fonts/Mukta-Light.ttf') format('truetype'),
        url('./fonts/Mukta-Light.svg#Mukta-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mukta';
    src: url('./fonts/Mukta-ExtraLight.eot');
    src: url('./fonts/Mukta-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mukta-ExtraLight.woff2') format('woff2'),
        url('./fonts/Mukta-ExtraLight.woff') format('woff'),
        url('./fonts/Mukta-ExtraLight.ttf') format('truetype'),
        url('./fonts/Mukta-ExtraLight.svg#Mukta-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mukta';
    src: url('./fonts/Mukta-Regular.eot');
    src: url('./fonts/Mukta-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mukta-Regular.woff2') format('woff2'),
        url('./fonts/Mukta-Regular.woff') format('woff'),
        url('./fonts/Mukta-Regular.ttf') format('truetype'),
        url('./fonts/Mukta-Regular.svg#Mukta-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mukta';
    src: url('./fonts/Mukta-SemiBold.eot');
    src: url('./fonts/Mukta-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mukta-SemiBold.woff2') format('woff2'),
        url('./fonts/Mukta-SemiBold.woff') format('woff'),
        url('./fonts/Mukta-SemiBold.ttf') format('truetype'),
        url('./fonts/Mukta-SemiBold.svg#Mukta-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mukta';
    src: url('./fonts/Mukta-Bold.eot');
    src: url('./fonts/Mukta-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mukta-Bold.woff2') format('woff2'),
        url('./fonts/Mukta-Bold.woff') format('woff'),
        url('./fonts/Mukta-Bold.ttf') format('truetype'),
        url('./fonts/Mukta-Bold.svg#Mukta-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mukta';
    src: url('./fonts/Mukta-ExtraBold.eot');
    src: url('./fonts/Mukta-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mukta-ExtraBold.woff2') format('woff2'),
        url('./fonts/Mukta-ExtraBold.woff') format('woff'),
        url('./fonts/Mukta-ExtraBold.ttf') format('truetype'),
        url('./fonts/Mukta-ExtraBold.svg#Mukta-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


body {
    font-family: 'Mukta' !important;
}
body, html {
    overflow-x: hidden;
}

.common-btn {
    padding: 10px 20px !important;
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-weight: normal !important;
    border-radius: 5px !important;
    text-transform: capitalize !important;
}

.common-btn.light {
    background: linear-gradient(195deg, #878787, #3d3d3d);
}

.common-btn.dark {
    background: linear-gradient(195deg, #42424a, #191919);
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: calc(50% - 15px);
}

.w-25 {
    width: calc(25% - 15px);
}

.w-33 {
    width: calc(33.33% - 15px);
}

.w-fill {
    width: -webkit-fill-available;
}

.w-auto {
    width: fit-content !important;
    display: inline-block;
    min-width: 150px;
}

.atLogo {
    width: 12px !important;
}

/* Pagination */
.MuiDataGrid-footerContainer {
    display: none !important;
}

.pagination-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.pagination-block .nextui-pagination-highlight--active {
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
}

.pagination-block .result-info {
    color: #727272;
}

.pagination-block .result-info span {
    color: #1A73E8;
    font-weight: bold;
}

.Toastify {
    z-index: 99999;
    position: fixed;
}

.rdrCalendarWrapper {
    width: 100% !important;
}

.nextui-modal-wrapper-enter-active {
    padding: 0 !important;
}

@media screen and (max-width: 991px) {
    .w-33 {
        width: calc(50% - 15px);
    }
}

@media screen and (max-width: 767px) {
    .w-50 {
        width: 100%;
    }

    .common-btn {
        font-size: 14px !important;
    }

    .pagination-block {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 15px;
    }
}

/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.center {
    text-align: center;
}

.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
}

.success-checkmark .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;
}

.success-checkmark .check-icon::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
    content: "";
    height: 100px;
    position: absolute;
    background: #FFFFFF;
    transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(76, 175, 80, 0.5);
}

.success-checkmark .check-icon .icon-fix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #FFFFFF;
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
    }

    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}


/* Preloader CSS */
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: #fff;
    top: 0;
    left: 0;
    font-size: 30px;
    vertical-align: center;
}

.preloader [className=loader] {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: var(--Orange);
    pointer-events: none;
}

.preloader .loader {
    animation: loader-1 2s infinite linear;
    position: absolute;
    top: 45%;
    left: 50%;
}

.preloader .loader:before,
.preloader .loader:after {
    content: "";
    width: 0;
    height: 0;
    border: 1rem solid currentcolor;
    display: block;
    position: absolute;
    border-radius: 100%;
    animation: loader-2 2s infinite ease-in-out;
}

.preloader .loader:before {
    top: 0;
    left: 50%;
}

.preloader .loader:after {
    bottom: 0;
    right: 50%;
    animation-delay: -1s;
}

@keyframes loader-1 {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-2 {

    0%,
    100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}