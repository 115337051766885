.form-row div.form-control {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.form-row label.form-label {
    font-size: 18px;
}

.customSelect {
    font-size: 20px;
}

.customSelect>div {
    padding: 4px 5px;
}

.form-row input,
.form-row textarea {
    font: inherit;
    width: 100%;
    font-size: 20px;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 10px 14px;
    color: #495057;
    background-color: transparent;
    border-radius: 0.375rem;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.form-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 10px;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]+label {
    padding: 11.5px 20px;
    border: 1px solid #CCC;
    border-radius: 0.375rem;
    padding-left: 40px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
}

input[type="radio"]:checked+label {
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    color: #FFFFFF;
}

input[type="radio"]+label::after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    left: 15px;
    top: 50%;
    background-color: #CCC;
    border-radius: 50%;
    transform: translateY(-50%);
}

input[type="radio"]:checked+label::after {
    background-color: #FFFFFF;
}

.TrustProfileLogo {
    position: relative;
    max-height: 315px;
    max-width: 315px;
    border-radius: 25px;
    padding: 5px;
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    width: 315px;
    height: 315px;
}

.TrustProfileLogo img.ProfileImg {
    width: 305px;
    height: 305px;
    object-fit: contain;
    padding: 5px;
    background: #FFFFFF;
    border-radius: 20px;
}

p.ProfileUploadImgTitle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-color: rgba(0 0 0 / 0.4);
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    font-size: 24px;
    border-radius: 20px;
    opacity: 0;
    -webkit-transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -ms-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
    transition: 0.4s ease all;
}

.TrustProfileLogo:hover p.ProfileUploadImgTitle {
    opacity: 1;
}

.paymentMethod {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

.radiobox {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.nextui-modal-header {
    background: linear-gradient(195deg, #42424a, #191919);
}

.nextui-modal-header p,
.nextui-modal-header p b {
    color: #FFFFFF;
}

.nextui-modal-footer {
    border-top: 1px solid #191919;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    margin-top: 30px;
    gap: 20px;
}

.rdrCalendarWrapper {
    font-size: 18px;
}

p.errorMessage {
    color: red;
}

.model-with-sidebar {
    display: flex !important;
    width: 100%;
    padding: 0 !important;
}

.AddDataModel {
    display: flex;
    padding: 0;
}

.AddDataModel .modelSidebat {
    width: 300px;
    text-align: left;
    background: linear-gradient(195deg, #42424a, #191919);
}

.AddDataModel .modelContent {
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.AddDataModel .modelSidebat ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    padding: 15px 0;
}

.AddDataModel .modelSidebat ul a {
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
}

.successBlock {
    padding: 50px 0;
}

.AddDataModel .modelSidebat ul a.success {
    background: linear-gradient(195deg, #62d69c, #23a848);
}

.AddDataModel .modelSidebat ul a.active {
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
}

.AddDataModel .modelSidebat ul a.error {
    background: linear-gradient(195deg, #f16262, #a82323);
}

.AddDataModel .modelSidebat ul a:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.AddDataModel .modelSidebat ul a span {
    width: 70px;
}

.AddDataModel .modelSidebat ul li {
    margin: 0 15px;
    margin-bottom: 15px;
}

.AddDataModel .modelSidebat ul a span:last-child {
    width: calc(100% - 70px);
}

.reqired-star {
    color: red;
    font-size: 25px;
    line-height: 15px;
}

.trustNote {
    margin: 10px;
}

.modelBottom {
    padding: 20px;
    border-top: 1px solid #191919;
}

.nextui-modal-close-icon {
    top: 12px !important;
}

.dashboard-tital {
    padding-bottom: 1%;
}

.font-xl h4 {
    font-size: 40px;
}
span.mobile-only {
    display: none;
}

@media screen and (max-width: 991px) {
    .nextui-backdrop-content {
        width: 95%;
    }

    .AddDataModel {
        flex-direction: column;
        gap: 20px;
    }

    .AddDataModel .modelSidebat {
        width: 100%;
        background: transparent;
        box-shadow: 0 0 10px 0 rgba(0 0 0/ 30%);
    }

    .AddDataModel .modelContent {
        width: 100%;
    }

    .AddDataModel .modelSidebat ul a span {
        display: none;
    }

    .AddDataModel .modelSidebat ul a span.mobile-only {
        display: block;
        width: auto;
    }

    .AddDataModel .modelSidebat ul {
        display: flex;
        align-items: center;
        padding: 15px 25px;
        gap: 20px;
    }

    .AddDataModel .modelSidebat ul li {
        position: relative;
        margin: 0;
    }
    
    .AddDataModel .modelSidebat ul li:not(:first-child)::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 90%;
        transform: translateY(-50%);
        width: 50px;
        height: 2px;
        background-color: #42424a;
        z-index: -1;
    }

    .AddDataModel .modelSidebat ul a {
        background-color: rgb(102 102 102);
        z-index: 1;
    }
    
    .AddDataModel .modelSidebat ul a:hover {
        background-color: rgb(51 51 51);
    }

    .TrustProfileLogo img.ProfileImg {
        width: 200px;
        height: 200px;
    }
    
    .TrustProfileLogo {
        max-width: 210px;
        max-height: 210px;
        margin-bottom: 15px;
    }

    .paymentMethod {
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 15px;
    }

    .form-row {
        row-gap: 0;
        flex-wrap: wrap;
    }

    .AddDataModel .modelSidebat ul a {
        width: 40px;
        height: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
    }
}

@media screen and (max-width: 767px) {
    .rdrCalendarWrapper {
        font-size: 14px;
    }

    .MainHeader {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
    }

    .MainHeader>div {
        width: auto;
    }

    .MainHeader .navToggle {
        display: flex;
        justify-content: flex-end;
    }

    p#modal-title,
    p#modal-title b {
        font-size: 20px;
        text-align: left;
    }

    .font-xl h4 {
        font-size: 30px;
    }
}

@media screen and (max-width: 480px) {
    .rdrCalendarWrapper {
        font-size: 12px;
    }
}