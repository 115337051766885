.profile {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 5% 2%;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    overflow: visible;
    background-color: #FFFFFF;
    margin-bottom: 30px;
}
.trust-details {
    max-width: 80%;
}

.ProfileCard {
    flex-direction: row !important;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.ProfileCard .ProfileImage {
    width: 80%;
}
.NavProfile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile h1 {
    text-align: center;
    margin-bottom: 20px;
}

.profile .profile-section {
    width: calc(50% - 15px);
}

.profile .profile-section:last-child {
    width: 100%;
}

.profile-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    font-family: 'Nexa';
}

.field-group.full-width {
    grid-template-columns: 1fr;
}

.field label {
    font-weight: bold;
    min-width: 160px;
}

.field p {
    margin: 0;
}

.field-group .field {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    font-size: 22px;
    margin-bottom: 15px;
}

@media screen and (max-width : 767px ) {
    .profile .profile-section {
        width: 100%;
    }
    .ProfileCard {
        margin-left: 10px;
        margin-right: 10px;
    }
    .ProfileImage {
        margin: 0 !important;
        width: 100% !important;
    }
    .ProfileImage .image,
    .ProfileImage .details {
        padding-left: 0 !important;
    }
    .ProfileImage h2 {
        font-size: 20px;
    }
    .ProfileImage h4 {
        font-size: 16px;
        margin-top: 5px;
    }
    .profile-section h2 {
        font-size: 20px;
    }
    .field label {
        min-width: 100%;
    }
    .field-group .field {
        font-size: 18px;
        gap: 0;
        white-space: pre;
        flex-wrap: wrap;
    }
    .profile {
        padding: 15% 0 5%;
    }
    .profile .profile-section p {
        font-size: 16px ;
    }
}