input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.title_text.roles-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3%;
}

.button-for-trust-actions {
    display: flex;
    gap: 1rem;
}

.button-for-trust-actions>button {
    padding: 5px 20px;
    border: 1px solid #919191;
    border-radius: 5px;
    background-color: transparent;
    color: #000;
    box-shadow: unset;
}

.searchbaar {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-width: 500px;
    width: 100%;
}

.serach_icon>svg {
    align-items: center;
    color: #00000078;
    display: flex;
    padding: 0 10px;
    font-size: 2.5rem !important;
}


.innerusercontent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0rem 0rem;
    margin-bottom: 1rem;
    text-align: start;
}

button.userfilter:hover,
button.userfilter:focus {
    background-color: #000 !important;
    padding: 7px 25px;
    color: #fff !important;
}

button.userfilter {
    padding: 5px 20px;
    border: 1px solid #919191;
    border-radius: 5px;
    background-color: transparent;
    color: #000;
    box-shadow: unset;
}

.inputsearchbase.base-css-trust>input {
    max-width: 300px;
    width: 100% !important;
}

.d-flex.justify-content-between.align-items-center>label {
    margin-right: 0px;
    color: #000 !important;
}

.d-flex.justify-content-between.align-items-center>label button {
    color: #403f3f;
}

.trust-border {
    padding: 0 2%;
    box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
    margin-top: 2%;
}

.filter-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 0;
}

.filter-buttons .btn:first-child {
    width: 30%;
}

.filter-buttons .btn:last-child {
    width: 70%;
}

.filter-type {
    margin-top: 20px;
    margin-bottom: 40px;
}

.view-donation {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.view-donation .view-donation-col {
    flex-grow: 1;
}

.view-donation .view-donation-col span {
    min-width: 310px;
    display: inline-block;
    font-weight: 500;
}

.view-donation .view-donation-col li:not(:last-child) {
    margin-bottom: 10px;
}

.view-donation .view-donation-col ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.view-donation .view-donation-col .view-tital h2 {
    margin-bottom: 15px;
}

div#demo-popup-menu .MuiPaper-root {
    max-width: 600px;
    width: 100%;
}

.hideButton {
    position: absolute !important;
    top: 50%;
    right: 0;
    font-size: 24px !important;
    transform: translateY(-50%);
    padding: 11.5px 20px !important;
    min-width: auto !important;
}

.passBox {
    position: relative;
}

@media screen and (max-width: 767px) {
    .innerusercontent {
        flex-wrap: wrap;
        gap: 20px;
    }

    .title_text.roles-header {
        flex-wrap: wrap;
        gap: 20px;
    }
    .title_text.roles-header {
        margin-bottom: 15px;
    }
}