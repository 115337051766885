.supportPage {
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    min-height: 400px;
    margin: 30px 0;
    border-radius: 0.75rem;
    padding: 30px;
}

.contact-info h1 {
    line-height: 1;
}

.contact-info {
    padding-top: 40px;
}

.info-title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: #868688;
}

.info-title a {
    color: black;
}

.info-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
    padding-right: 5px;
    transform: translateY(5px);
}

.info-content {
    color: #413f42;
    font-size: 24px;
    padding-left: 40px;
}

.logo-container {
    margin-top: 120px;
    text-align: center;
}

.supportblock {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px 30px;
}

.supportPage.home {
    box-shadow: none;
    margin: 0;
    min-height: auto;
    padding: 30px 10px;
}